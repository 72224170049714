@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.content,
.app {
  height: 100%;
  width: 100% !important;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-y: scroll;
}

.app {
  display: flex;
  position: relative;
}

.content a {
  text-decoration: 'none !important';
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.error {
  color: red;
}
